
import { defineComponent, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'resetPassword',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      password: '',
      password_confirmation: '',
      loading: false,
      token: this.$route.params.token as any,
    };
  },
  methods: {
    async formSubmit() {
      let formData = new FormData();
      formData.set('password', this.password);
      formData.set('password_confirmation', this.password_confirmation);
      formData.set('token', this.token);

      this.loading = true;
      await ApiService.post('auth/resetPass', formData)
        .then((response) => {
          this.loading = false;
          if (response.data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.$router.push('/sign-in');
              this.password = '';
              this.password_confirmation = '';
              // redirect to signin or login page
            });
          } else if (response.data.status === 'error') {
            Swal.fire({
              title: 'Error',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const ResetSchema = Yup.object().shape({});

    //Form submit function

    return {
      ResetSchema,
    };
  },
});
